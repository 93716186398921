import { useFormState } from "react-final-form";
import { FunctionField } from "react-admin";
import { getDependencyDegree, getLikes, getTechnologicalIndependence, getTherapeuticProfile, getUsesTechHelp } from "../../services/master-tables.service";
import { useEffect, useState } from "react";
import { GetApp } from '@material-ui/icons';
import {  Tooltip } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

import { saveAs } from 'file-saver';
var XLSX = require("xlsx");

export const PanelObservaciones = (props) => {
    const [gradoDependencia, setGradoDependencia] = useState()
    const [gustos, setGustos] = useState()
    const [perfilTerapeutico, setPerfilTerapeutico] = useState()
    const [autonomia, setAutonomia] = useState()
    const [ayudaTecnica, setAyudaTecnica] = useState()

    const { values } = useFormState();

    useEffect(() => {
        getDependencyDegree().then(res => setGradoDependencia(res.elements.find(e => e.id === values.dependencyDegreeId)?.value))
        getLikes().then(res => setGustos(res.elements))
        getTherapeuticProfile().then(res => setPerfilTerapeutico(res.elements))
        getTechnologicalIndependence().then(res => setAutonomia(res.elements.find(e => e.id === values.technologicalIndependenceId)?.value))
        getUsesTechHelp().then(res => setAyudaTecnica(res.elements.find(e => e.id === values.usesTechHelpId)?.value))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const descargar = () => {
        const xlsxBlob = arrayToXlsx([{
            Perfil_Asistencia: values?.therapeuticalProfiles?.map(l => perfilTerapeutico?.find(p => p.id === l)?.value)?.join(', '),
            Autonomía: autonomia,
            Ayudas_Técnicas: (ayudaTecnica || '') + " " + (ayudaTecnica ? (values?.whichOnesTechHelp || '') : ''),
            Grado_Dependencia: gradoDependencia,
            Gustos_Preferencias: values?.clientLikes?.map(l => gustos?.find(g => g.id === l)?.value)?.join(', '),
            Personas_Contacto: props.contactos?.map(c => renderContact(c))?.join(', '),
            Trabajador_Social_Referencia: values?.referenceSocialWorker?.name + " " + values?.referenceSocialWorker?.surnames
        }]);
        saveAs(xlsxBlob, 'Observaciones.xlsx');
    }

    const arrayToXlsx = (array) => {
        const worksheet = XLSX.utils.json_to_sheet(array);
        const columnWidths = Object.keys(array[0]).map((col) => {
            const maxWidth = array.reduce((max, row) => Math.max(max, (row[col] || "").toString().length), 10);
            return { wch: maxWidth + 5 }; // Ajusta el margen sumando un valor adicional
        });
        worksheet["!cols"] = columnWidths;
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const xlsxBlob = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        return new Blob([xlsxBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
    };

    return (
        <div style={{
            display: 'flex', flexDirection: 'column', gap: 12,
            backgroundColor: '#f0f0f0', borderRadius: '8px', padding: 8
        }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h3>Observaciones generales</h3>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 3, paddingLeft: '1rem' }}>
                    <GetApp style={{ color: "#00ab8e", cursor: "pointer" }} onClick={descargar} />
                </div>
            </div>
            <div className="divide-x"
                style={{
                    display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    width: '100%'
                }}>
                <div style={{ display: 'grid', gap: 3, gridTemplateRows: 'auto auto auto', height: '100%' }}>
                    <FunctionField render={record => "Perfil de asistencia: " + record?.therapeuticalProfiles?.map(l => perfilTerapeutico?.find(p => p.id === l)?.value)?.join(', ')} />
                    <FunctionField render={record => "Autonomía: " + (autonomia || '')} />
                    <FunctionField render={record => "Ayudas técnicas: " + (ayudaTecnica || '') + " " + (ayudaTecnica ? (record?.whichOnesTechHelp || '') : '')} />
                </div>
                <div style={{ display: 'grid', gap: 3, gridTemplateRows: 'auto auto auto', height: '100%', paddingLeft: '1rem' }}>
                    <FunctionField render={record => "Grado de dependencia: " + (gradoDependencia || '')} />
                    <FunctionField render={record => "Gustos/Preferencias: " + record?.clientLikes?.map(l => gustos?.find(g => g.id === l)?.value)?.join(', ')} />
                </div>
                <div style={{ display: 'grid', gap: 3, gridTemplateRows: 'auto auto auto', height: '100%', paddingLeft: '1rem' }}>
                    <Tooltip title={ReactHtmlParser(props.contactos?.length > 3? props.contactos?.map(c => renderContact(c))?.join('<br>'):'')} placement="right">
                        <span>
                            <FunctionField render={record => "Personas de contacto: " + props.contactos?.slice(0, 3)?.map(c => renderContact(c))?.join(', ') + (props.contactos?.length > 3 ? '...' : '')} />
                        </span>
                    </Tooltip>
                    <FunctionField render={record => "Trabajador social de referencia: " + record?.referenceSocialWorker?.name + " " + record?.referenceSocialWorker?.surnames} />
                </div>
            </div>
        </div>
    );
}

const renderContact = (c) => {
    var data = ""
    if (c.name && c.surnames) data = c.name + " " + c.surnames
    else if (c.name) data = c.name
    else if (c.surnames) data = c.surnames
    else if (c.phone1) data = c.phone1
    else if (c.phone2) data = c.phone2
    else if (c.email) data = c.email

    return data
}