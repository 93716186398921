import { useFormState } from "react-final-form";
import { FunctionField } from "react-admin";
import moment from 'moment'
// import StarPicker from "react-star-picker";
import { Add } from '@material-ui/icons';
import { ExternalInformationClient } from "../../services/ohl.virtualCenter.api";
import { useEffect, useState } from "react";

import { mapaPestanas, PESTANA_CONTRATOS } from "./utils";

const formatearFecha = (fecha) => fecha ? moment(fecha).format('DD/MM/YYYY') : ''

export const PanelVariado = (props) => {
    const externalInformationClient = new ExternalInformationClient(process.env.REACT_APP_PUBLIC_API)

    const [lastInteraction, setLastInteraction] = useState()

    const { values } = useFormState();
    const statusLabel = {
        ACTIVE: 'ACTIVO',
        INACTIVE: 'INACTIVO',
        LEAVE: 'BAJA',
    }

    const indicePestana = (pestana) => {
        return mapaPestanas[pestana][props.identity.roles]
    }
    
    useEffect(() => {
        externalInformationClient.lastInteraction({clientId: values?.id}).then(interaction => setLastInteraction(interaction))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '3rem', width: '100%', height: '10rem'}}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 8, 
                backgroundColor:'#f0f0f0', borderRadius: '8px', padding: 8}}>
                <div style={{display: 'flex', justifyContent:'space-between', alignItems: 'center', gap: 6}}>
                    <h3>Contratos</h3>
                    <div style={{display: 'flex', justifyContent:'space-between', alignItems: 'center', gap: 6, width: '100%'}}>
                        {
                            props.identity.roles !== 'USER_HISTORY_MANAGER' && props.identity.roles!== 'AAPP_TECHNICIAN' ? 
                            <>
                                <FunctionField style={{backgroundColor: '#00ab8e', borderRadius: '20px', height: 'fit-content',
                                    textAlign: 'center', paddingLeft: '8px', paddingRight: '8px', color:'white'}} 
                                    render={record => values.contracts?.find(c => c?.id === record.mainContractId)?.contractNumber}
                                />
                                <a style={{color: '#00ab8e', textDecoration:'none'}} 
                                href={`/#/users/${values?.id}/${indicePestana(PESTANA_CONTRATOS)}`}>
                                    <Add />
                                </a>
                            </> : null
                        }
                    </div>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 6, backgroundColor: 'white', padding: 10, borderRadius: 8, height: '100%'}}>
                    {
                        props.identity.roles !== 'USER_HISTORY_MANAGER' && props.identity.roles!== 'AAPP_TECHNICIAN' && values.contracts?.map(c => <FunctionField style={{backgroundColor: '#00ab8e', borderRadius: '20px', height: 'fit-content',
                            textAlign: 'center', paddingLeft: '8px', paddingRight: '8px', color:'white'}} 
                            render={record => c?.contractNumber} />)
                    }
                </div>
                {
                    props.identity.roles !== 'USER_HISTORY_MANAGER' && props.identity.roles!== 'AAPP_TECHNICIAN' ?
                        <div style={{display: 'flex', justifyContent: 'end'}}>
                            <a href={`/#/users/${values?.id}/${indicePestana(PESTANA_CONTRATOS)}`} style={{backgroundColor: '#aaaaaa', borderRadius: '20px', height: 'fit-content',
                                textAlign: 'center', paddingLeft: '8px', paddingRight: '8px', color:'white', textDecoration: 'none'}} >Ver Más</a>
                        </div>
                    : null
                }
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: 8, 
                backgroundColor:'#f0f0f0', borderRadius: '8px', padding: 8}}>
                <div style={{display: 'flex', justifyContent:'space-between', gap: 6}}>
                <h3>Servicios contratados</h3>
                    <span style={{color: '#00ab8e', textDecoration:'none', cursor: 'pointer'}} 
                        // href={`/#/users/${values?.id}/8`}
                    >
                        <Add />
                    </span>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 6, backgroundColor: 'white', padding: 10, borderRadius: 8, height: '100%'}}>
                   
                </div>
                <div style={{display: 'flex', justifyContent: 'end'}}>
                    <span style={{backgroundColor: '#aaaaaa', borderRadius: '20px', height: 'fit-content', cursor: 'pointer',
                        textAlign: 'center', paddingLeft: '8px', paddingRight: '8px', color:'white', textDecoration: 'none'}} >Ver Más</span>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: 8, 
                backgroundColor:'#f0f0f0', borderRadius: '8px', padding: 8}}>
                <h3>Registro de actividad</h3>
                <div style={{display: 'grid', gridTemplateColumns: 'auto auto', columnGap: 8, whiteSpace: 'nowrap',
                    backgroundColor: 'white', padding: 10, borderRadius: 8, height: '100%'}}>
                    <FunctionField render={record => "Fecha alta: "+formatearFecha(record.activeDate)} />
                    <FunctionField render={record => "Estado: "+statusLabel[record.active]} />
                    <FunctionField render={record => "Fecha baja: "+formatearFecha(record.leavingDate)} />
                    <FunctionField render={record => "Última sesión: "+ (props.identity.roles !== 'USER_HISTORY_MANAGER' && props.identity.roles!== 'AAPP_TECHNICIAN' ? formatearFecha(lastInteraction?.creationDate): '')} />
                    <FunctionField render={record => "Fecha inactividad: "+formatearFecha(record.inactivityDate)} />
                    </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: 8, 
                backgroundColor:'#f0f0f0', borderRadius: '8px', padding: 8}}>
                <h3>Valoración media servicio</h3>
                <div style={{display: 'flex', alignItems: 'center', height: '100%', width: '100%'}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: 6, width: '100%',
                        backgroundColor: 'white', padding: 10, borderRadius: 8, height: '100%'}}>
                        {/* <StarPicker style={{ marginTop: 10 }} value={4}
                                numberStars={6} doubleTapResets size={30} /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}